/* eslint-disable jsx-a11y/anchor-is-valid */

import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import Logo from "../assets/front-image.jpg";
import Logo2 from "../assets/front-image2.jpg";
function Home() {
  return (
    <div>
      <div className='container-fluid p-0 mb-5'>
        <div
          id='header-carousel'
          className='carousel slide'
          data-bs-ride='carousel'>
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <img className='w-100' src={Logo2} alt='normal' />
              <div className='carousel-caption'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-7 pt-5'>
                      <h2 className='display-4 text-white mb-3 animated slideInDown'>
                        Preserving Nature's Beauty: Say NO to Destructive Gold
                        Mining!
                      </h2>
                      <p className='fs-5 text-white-50 mb-5 animated slideInDown'>
                        Every glittering ounce of gold comes at a cost to our
                        precious planet. We believe in preserving nature's
                        beauty and saying NO to the destructive practices of
                        gold mining. Join us in our commitment to protect the
                        earth's delicate ecosystems and ensure a sustainable
                        future for generations to come.
                      </p>
                      <a
                        className='btn btn-primary py-2 px-3 animated slideInDown'
                        href=''>
                        Learn More
                        <div className='d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2'>
                          <i className='fa fa-arrow-right'></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='carousel-item'>
              <img className='w-100' src={Logo} alt='normal' />
              <div className='carousel-caption'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-7 pt-5'>
                      <h2 className='display-4 text-white mb-3 animated slideInDown'>
                        Let's Protect Our Environment For a Better Livelihood
                      </h2>
                      <p className='fs-5 text-white-50 mb-5 animated slideInDown'>
                        A clean and healthy environment promotes physical and
                        mental well-being. Access to clean air, safe water, and
                        unpolluted surroundings helps prevent diseases, reduces
                        stress levels, and improves overall quality of life
                      </p>
                      <a
                        className='btn btn-primary py-2 px-3 animated slideInDown'
                        href=''>
                        Learn More
                        <div className='d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2'>
                          <i className='fa fa-arrow-right'></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#header-carousel'
            data-bs-slide='prev'>
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#header-carousel'
            data-bs-slide='next'>
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
      <div className='container-xxl py-5'>
        <div className='container'>
          <div className='row g-5'>
            <div className='col-lg-6 wow fadeInUp' data-wow-delay='0.1s'>
              <div
                className='position-relative overflow-hidden h-100'
                style={{ minHeight: "400px" }}>
                <img
                  className='position-absolute w-100 h-100 pt-5 pe-5'
                  src='img/about-1.jpg'
                  alt=''
                  style={{ objectFit: "cover" }}
                />
                <img
                  className='position-absolute top-0 end-0 bg-white ps-2 pb-2'
                  src='img/about-2.jpg'
                  alt=''
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
            </div>
            <div className='col-lg-6 wow fadeInUp' data-wow-delay='0.5s'>
              <div className='h-100'>
                <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
                  About Us
                </div>
                <h1 className='display-6 mb-5'>
                  We campaign for sustainability and promote human rights
                </h1>
                <div className='bg-light border-bottom border-5 border-primary rounded p-4 mb-4'>
                  <p className='text-dark mb-2'>
                    At the heart of our mission lies a dedication to
                    sustainability and the safeguarding of human rights. We
                    tirelessly campaign to harmonize progress with the
                    well-being of both our planet and its inhabitants. Join us
                    in promoting a world where ecological balance and human
                    dignity are held above all else.
                  </p>
                  <span className='text-primary'>
                    Mr. Richard Adjei Poku, CEO
                  </span>
                </div>

                <a className='btn btn-primary py-2 px-3 me-3' href=''>
                  Learn More
                  <div className='d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2'>
                    <i className='fa fa-arrow-right'></i>
                  </div>
                </a>
                <a className='btn btn-outline-primary py-2 px-3' href=''>
                  Contact Us
                  <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                    <i className='fa fa-arrow-right'></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl bg-light my-5 py-5'>
        <div className='container py-5'>
          <div
            className='text-center mx-auto mb-5 wow fadeInUp'
            data-wow-delay='0.1s'
            style={{ maxWidth: "500px" }}>
            <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
              Feature Causes
            </div>
            <h1 className='display-6 mb-5'>
              Every Human Deserves To Have Good Livelihood
            </h1>
          </div>
          <div className='row g-4 justify-content-center'>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.1s'>
              <div className='causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100'>
                <div className='text-center p-4 pt-0'>
                  <div className='d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4'>
                    <small>Mission</small>
                  </div>
                  <h5 className='mb-3'>Educate People on their right</h5>
                  <p>
                    To promote human rights, campaign for environmental
                    sustainability and enhance livelihoods of the marginalized
                    people in society
                  </p>
                </div>
                <div className='position-relative mt-auto'>
                  <img className='img-fluid' src='img/courses-1.jpg' alt='' />
                  <div className='causes-overlay'>
                    <a className='btn btn-outline-primary' href=''>
                      Read More
                      <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                        <i className='fa fa-arrow-right'></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.3s'>
              <div className='causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100'>
                <div className='text-center p-4 pt-0'>
                  <div className='d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4'>
                    <small>Vision</small>
                  </div>
                  <h5 className='mb-3'>
                    Ensure Better sustainability of human rights
                  </h5>
                  <p>
                    To become the most vibrant human rights and environmental
                    sustainability champion in the sub-saharan
                  </p>
                </div>
                <div className='position-relative mt-auto'>
                  <img className='img-fluid' src='img/courses-2.jpg' alt='' />
                  <div className='causes-overlay'>
                    <a className='btn btn-outline-primary' href=''>
                      Read More
                      <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                        <i className='fa fa-arrow-right'></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.5s'>
              <div className='causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100'>
                <div className='text-center p-4 pt-0'>
                  <div className='d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4'>
                    <small>Values</small>
                  </div>
                  <h5 className='mb-3'>Rights Advocate </h5>
                  <p>
                    We respect human rights, passion for Nature, Patriotism,
                    service,and Teamwork
                  </p>
                </div>
                <div className='position-relative mt-auto'>
                  <img className='img-fluid' src='img/courses-3.jpg' alt='' />
                  <div className='causes-overlay'>
                    <a className='btn btn-outline-primary' href=''>
                      Read More
                      <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                        <i className='fa fa-arrow-right'></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl py-5'>
        <div className='container'>
          <div
            className='text-center mx-auto mb-5 wow fadeInUp'
            data-wow-delay='0.1s'
            style={{ maxWidth: "500px" }}>
            <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
              What We Do
            </div>
            <h1 className='display-6 mb-5'>
              Learn More What We Do And Get Involved
            </h1>
          </div>
          <div className='row g-4 justify-content-center'>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.1s'>
              <div className='service-item bg-white text-center h-100 p-4 p-xl-5'>
                <img className='img-fluid mb-4' src='img/icon-1.png' alt='' />
                <h4 className='mb-3'>Human Education</h4>
                <p className='mb-4'>
                  We educate people on their human rights and how they can
                  protect their environment for better livelihood
                </p>
                <a className='btn btn-outline-primary px-3' href=''>
                  Learn More
                  <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                    <i className='fa fa-arrow-right'></i>
                  </div>
                </a>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.3s'>
              <div className='service-item bg-white text-center h-100 p-4 p-xl-5'>
                <img className='img-fluid mb-4' src='img/icon-2.png' alt='' />
                <h4 className='mb-3'>Advocacy</h4>
                <p className='mb-4'>
                  Amplifying Voices for Change. Join us in making a difference
                  by raising awareness, driving action, and creating a brighter
                  future for all
                </p>
                <a className='btn btn-outline-primary px-3' href=''>
                  Learn More
                  <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                    <i className='fa fa-arrow-right'></i>
                  </div>
                </a>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 wow fadeInUp'
              data-wow-delay='0.5s'>
              <div className='service-item bg-white text-center h-100 p-4 p-xl-5'>
                <img className='img-fluid mb-4' src='img/icon-3.png' alt='' />
                <h4 className='mb-3'>Good Livelihood</h4>
                <p className='mb-4'>
                  Building Sustainable Futures. We're dedicated to empowering
                  communities with opportunities that foster prosperity,
                  well-being, and a meaningful way of life
                </p>
                <a className='btn btn-outline-primary px-3' href=''>
                  Learn More
                  <div className='d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2'>
                    <i className='fa fa-arrow-right'></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='container-fluid donate my-5 py-5'
        data-parallax='scroll'
        data-image-src='img/carousel-2.jpg'>
        <div className='container py-5'>
          <div className='row g-5 align-items-center'>
            <div className='col-lg-6 wow fadeIn' data-wow-delay='0.1s'>
              <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
                Donate Now
              </div>
              <h1 className='display-6 text-white mb-5'>
                Thanks For The Results Achieved With You
              </h1>
              <p className='text-white-50 mb-0'>
                Deeply grateful for your support, dedication, and kindness. Your
                contributions are the driving force behind our mission's
                success.
              </p>
            </div>
            <div className='col-lg-6 wow fadeIn' data-wow-delay='0.5s'>
              <div className='h-100 bg-white p-5'>
                <form>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <div className='form-floating'>
                        <input
                          type='text'
                          className='form-control bg-light border-0'
                          id='name'
                          placeholder='Your Name'
                        />
                        <label htmlFor='name'>Your Name</label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='form-floating'>
                        <input
                          type='email'
                          className='form-control bg-light border-0'
                          id='email'
                          placeholder='Your Email'
                        />
                        <label htmlFor='email'>Your Email</label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='btn-group d-flex justify-content-around'>
                        <input
                          type='radio'
                          className='btn-check'
                          name='btnradio'
                          id='btnradio1'
                          checked
                        />
                        <label
                          className='btn btn-light py-3'
                          htmlFor='btnradio1'>
                          $10
                        </label>

                        <input
                          type='radio'
                          className='btn-check'
                          name='btnradio'
                          id='btnradio2'
                        />
                        <label
                          className='btn btn-light py-3'
                          htmlFor='btnradio2'>
                          $20
                        </label>

                        <input
                          type='radio'
                          className='btn-check'
                          name='btnradio'
                          id='btnradio3'
                        />
                        <label
                          className='btn btn-light py-3'
                          htmlFor='btnradio3'>
                          $30
                        </label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <button
                        className='btn btn-primary px-5'
                        style={{ height: "60px" }}>
                        Donate Now
                        <div className='d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2'>
                          <i className='fa fa-arrow-right'></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl py-5'>
        <div className='container'>
          <div
            className='text-center mx-auto mb-5 wow fadeInUp'
            data-wow-delay='0.1s'
            style={{ maxWidth: "500px" }}>
            <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
              Team Members
            </div>
            <h1 className='display-6 mb-5'>
              Let's Meet With Our Ordinary Soldiers
            </h1>
          </div>
          <div className='row g-4'>
            <div
              className='col-lg-3 col-md-6 wow fadeInUp'
              data-wow-delay='0.1s'>
              <div className='team-item position-relative rounded overflow-hidden'>
                <div className='overflow-hidden'>
                  <img className='img-fluid' src='img/team-1.jpg' alt='' />
                </div>
                <div className='team-text bg-light text-center p-4'>
                  <h5>Mr. Richard Adjei-Poku</h5>
                  <p className='text-primary'>EXECUTIVE DIRECTOR</p>
                  <div className='team-social text-center'>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-twitter'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 wow fadeInUp'
              data-wow-delay='0.3s'>
              <div className='team-item position-relative rounded overflow-hidden'>
                <div className='overflow-hidden'>
                  <img className='img-fluid' src='img/team-2.jpg' alt='' />
                </div>
                <div className='team-text bg-light text-center p-4'>
                  <h5>Mr. Iddrisu Mahama Dominic</h5>
                  <p className='text-primary'>DIRECTOR OF FINANCE</p>
                  <div className='team-social text-center'>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-twitter'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 wow fadeInUp'
              data-wow-delay='0.5s'>
              <div className='team-item position-relative rounded overflow-hidden'>
                <div className='overflow-hidden'>
                  <img className='img-fluid' src='img/team-3.jpg' alt='' />
                </div>
                <div className='team-text bg-light text-center p-4'>
                  <h5>Rockyphella Oppong</h5>
                  <p className='text-primary'>IT DIRECTOR</p>
                  <div className='team-social text-center'>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-twitter'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 wow fadeInUp'
              data-wow-delay='0.7s'>
              <div className='team-item position-relative rounded overflow-hidden'>
                <div className='overflow-hidden'>
                  <img className='img-fluid' src='img/team-4.jpg' alt='' />
                </div>
                <div className='team-text bg-light text-center p-4'>
                  <h5>Ms. Edith Mborate</h5>
                  <p className='text-primary'>DIRECTOR OF COMMUNICATIONS</p>
                  <div className='team-social text-center'>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-twitter'></i>
                    </a>
                    <a className='btn btn-square' href=''>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl py-5'>
        <div className='container'>
          <div
            className='text-center mx-auto mb-5 wow fadeInUp'
            data-wow-delay='0.1s'
            style={{ maxWidth: "500px" }}>
            <div className='d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3'>
              Testimonial
            </div>
            <h1 className='display-6 mb-5'>
              Trusted By Thousands Of People And Nonprofits
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
