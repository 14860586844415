/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container, Typography } from "@mui/material";
export default function AboutUs() {
  return (
    <div>
      <div
        class='container-fluid page-header mb-5 wow fadeIn'
        data-wow-delay='0.1s'>
        <div class='container text-center'>
          <h1 class='display-4 text-white animated slideInDown mb-4'>
            About Us
          </h1>
          <nav aria-label='breadcrumb animated slideInDown'>
            <ol class='breadcrumb justify-content-center mb-0'>
              <li class='breadcrumb-item'>
                <a class='text-white' href='/'>
                  Home
                </a>
              </li>
              <li class='breadcrumb-item'>
                <a class='text-white' href='#'>
                  Pages
                </a>
              </li>
              <li
                class='breadcrumb-item text-primary active'
                aria-current='page'>
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container>
        <Typography
          style={{ textAlign: "justify", lineHeight: 2 }}
          variant='body1'>
          <p className='text-dark mb-2'>
            Livelihood & Environment Ghana – LEG is a research and advocacy
            not-for-profit and non-governmental organization established in
            July, 2004 in response to the growing social, environmental and
            human rights threats from the operations of Newmont Ahafo gold mine
            operations. Ever since, LEG has been working to promote sustainable
            livelihoods, campaign for environmental sustainability and promote
            community rights. The organization which was established in one
            community (Ahafo Kenyasi No.2), now operates in the entire Ghana
            particularly the seven mining regions namely Ahafo, Bono, Western
            North, Western, Ashanti, Eastern and Central Regions. LEG has nine
            competent Board of Directors with Professor (Mrs.) Mercy Afua
            Adutwumwa Derkyi as Chair, Dr (Mrs.) Erica Kuma MacGranaham of KATH
            as vice Chair, and Professor John Adu Kumi as Secretary among others
            who provide oversight responsibility for the smooth running of the
            organization. The organization also has nineteen Community Voluntary
            Facilitators (VCFs) within the seven administrative Regions of Ghana
            who serve as intermediaries between their communities and the
            organization
          </p>
        </Typography>
        <div className='container-xxl py-5'>
          <div className='container'>
            <div
              className='text-center mx-auto mb-5 wow fadeInUp'
              data-wow-delay='0.1s'
              style={{ maxWidth: "500px" }}></div>
            <div className='row g-4'>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.5s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p5.jpg' alt='' />
                  </div>
                  <div className='team-text bg-light text-center p-4'>
                    <h6>2022 Annual General Meeting</h6>
                  </div>
                </div>
              </div>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.7s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p6.jpg' alt='' />
                  </div>
                  <div className='team-text bg-light text-center p-4'>
                    <h6>2022 Annual General Meeting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Typography
          style={{ textAlign: "justify", lineHeight: 2 }}
          variant='body1'>
          In relation to sustainable livelihood promotion, the organization has
          trained three thousand five hundred and eighty (3,580) women on skills
          training. Again, LEG together with Tano Women Empowerment and
          Development Association (TWEDA) in partnership with Sunyani Area
          Teachers Cooperative Credit Union (SATCCU) have provided a cash amount
          of 500,000 cedis to 3,500 women as soft loan in the last seven years.
          Apart from skills training we also give them health talks. From time
          to time we send Doctors and Nurses to educate them on their health.
          And some topics include Hepatitis, Breast Cancer, stillbirths and
          maternal, perinatal and neonatal mortality, fatality rates and HIV
          AIDS. We sometimes provide them with free screening particularly
          Hepatitis and breast cancer. Photographs at some of the skills
          training sections (beads and soap making) On campaign for
          environmental sustainability the organization has done hundreds of
          education on radio, TV, Print and electronic media and face-face
          education. We have also undertaken tree planting exercises including
          Rivers Tano and Akantansu Restoration projects. Photographs of Tano
          Restoration Project at Ntotroso in Ahafo Region (2009). Climate change
          awareness education and radio education on environmental
          sustainability Promotion of community rights, LEG has not relented on
          this thematic area at all. We have offered series of training on
          compensation and resettlement and paralegal trainings on community
          rights to almost every mining affected community in Ghana including
          traditional leaders. We have also done litigation. For example, TWEDA
          together with LEG with financial support from GGF took Newmont to
          court in 2012 on behalf of Salamatu Ibrahim who was knocked-down by
          Newmont vehicle and sustained serious injuries and deformity. LEG with
          support from the same GGF also took Chirano Gold Mines to court on
          behalf of farmers who were under-compensated by Chirano Gold Mines and
          won the case in 2015.
        </Typography>
        <div className='overflow-hidden'>
          <img className='img-fluid' src='img/p7.jpg' alt='' />
        </div>
        <div className='team-text bg-light text-center p-4'>
          <em>
            Publication about Salamatu and Newmont/Pergah court case, (2012)
          </em>
        </div>
        <div className='container-xxl py-5'>
          <div className='container'>
            <div
              className='text-center mx-auto mb-5 wow fadeInUp'
              data-wow-delay='0.1s'
              style={{ maxWidth: "500px" }}></div>
            <div className='row g-4'>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.5s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/pic1.jpg' alt='' />
                  </div>
                </div>
              </div>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.7s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/pic2.jpg' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='team-text bg-light text-center p-4'>
          <em>
            Photographs at some of the skills training sections (beads and soap
            making)
          </em>
        </div>
        <Typography
          style={{ textAlign: "justify", lineHeight: 2 }}
          variant='body1'>
          On campaign for environmental sustainability the organization has done
          hundreds of education on radio, TV, Print and electronic media and
          face-face education. We have also undertaken tree planting exercises
          including Rivers Tano and Akantansu Restoration projects.
        </Typography>
        <div className='container-xxl py-5'>
          <div className='container'>
            <div
              className='text-center mx-auto mb-5 wow fadeInUp'
              data-wow-delay='0.1s'
              style={{ maxWidth: "500px" }}></div>
            <div className='row g-4'>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.5s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p3.jpg' alt='' />
                  </div>
                </div>
              </div>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.7s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p4.jpg' alt='' />
                  </div>
                </div>
              </div>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.7s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p8.jpg' alt='' />
                  </div>
                </div>
              </div>
              <div
                className='col-lg-3 col-md-6 wow fadeInUp'
                data-wow-delay='0.7s'>
                <div className='team-item position-relative rounded overflow-hidden'>
                  <div className='overflow-hidden'>
                    <img className='img-fluid' src='img/p9.jpg' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='team-text bg-light text-center p-4'>
          <em>
            Photographs of Tano Restoration Project at Ntotroso in Ahafo Region
            (2009). And Climate change awareness education and radio education
            on environmental sustainability
          </em>
        </div>
        <div className='overflow-hidden'>
          <img className='img-fluid' src='img/p11.jpg' alt='' />
        </div>
        <div className='team-text bg-light text-center p-4'>
          <em>
            News Paper publication on the payment of compensation arrears by
            Chirano Gold Mines, (2015)
          </em>
        </div>
        <Typography style={{ textAlign: "justify", lineHeight: 2 }}>
          LEG is now working on People Voice Project (PVP) under the Fair For
          All Programme in partnership with the Third World Network-Africa
          (TWN-Africa) with support from the Dutch Ministry of Foreign Affairs.
          The PVP focus on strengthening and building capacity of beneficiary
          organizations to be able to engage and mobilize audiences and policy
          makers to change and formulate policies, empower women access to
          productive resources and assets, collection of evidence of private
          sector abusing rights, support rights defenders to seek redress and
          holding private sector actors to account.
        </Typography>
      </Container>
    </div>
  );
}
