import Home from "./Home/Home";
import "./App.css";
import Nav from "./Header/Navbar";
import AboutUs from "./About/AboutUs";
import Facilitators from "./Facilitators/Facilitators";
import Directors from "./Directors/Directors";
import { Outlet, Route, Routes } from "react-router-dom";
import Bottom from "./Bottom/Bottom";
function App() {
  return (
    <div className="App">
      <Nav />
      <Outlet />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/facilitators" element={<Facilitators />} />
        <Route path="/Directors" element={<Directors />} />
      </Routes>
      <Bottom />
    </div>
  );
}

export default App;
