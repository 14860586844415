import { Container, Typography } from "@mui/material";
import * as React from "react";
import { facilitators } from "../facilitators";
export default function Facilitators() {
  return (
    <div>
      <div
        class="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">
            Facilitators
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-white" href="#">
                  Pages
                </a>
              </li>
              <li
                class="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Facilitators
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container>
        <Typography variant="h3">Meet Our Dedicated Facilitators</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "justify", lineHeight: 2 }}
        >
          At the heart of our NGO's mission are the passionate and committed
          facilitators who tirelessly work in towns across the region. These
          remarkable individuals form the backbone of our organization, bringing
          positive change to communities, one town at a time. Our facilitators
          come from diverse backgrounds, each contributing a unique set of
          skills and experiences. They share a common goal: to empower and
          uplift the lives of the people they serve. Whether it's providing
          education, healthcare, or fostering community development, our
          facilitators are on the front lines, making a real difference. With
          unwavering dedication, they bridge gaps, foster collaboration, and
          inspire hope in the communities they touch. Their compassion knows no
          bounds, and their impact extends far beyond the towns they serve. Get
          to know the faces behind the incredible work they do. Click on their
          pictures below to learn more about their stories, the communities they
          serve, and the transformative projects they lead. Join us in
          celebrating these remarkable individuals and the positive change they
          bring to the world. Together, we are making a difference, one town,
          one facilitator, at a time.
        </Typography>
      </Container>
    </div>
  );
}
