/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

function Nav() {
  return (
    <div>
      <div
        className="container-fluid fixed-top px-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
          <div className="col-lg-6 px-5 text-start">
            <small>
              <i className="fa fa-map-marker-alt me-2"></i>P.O.Box 1814,
              Sunyani, GHANA
            </small>
            <small className="ms-4">
              <i className="fa fa-envelope me-2"></i>leg2004@yahoo.com
            </small>
          </div>
          <div className="col-lg-6 px-5 text-end">
            <small>Follow us:</small>
            <a className="text-white-50 ms-3" href="">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="text-white-50 ms-3" href="">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="text-white-50 ms-3" href="">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a className="text-white-50 ms-3" href="">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        <nav
          className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
            <h3 className="fw-bold  m-0" style={{ color: "#008000 " }}>
              Livelihood<span className="text-white">&</span>
              Environment Gh.
            </h3>
          </a>
          <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <a href="/" className="nav-item nav-link active">
                Home
              </a>
              <a href="/about-us" className="nav-item nav-link">
                About Us
              </a>
              <a href="/" className="nav-item nav-link">
                Publications
              </a>
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu m-0">
                  <a href="service.html" className="dropdown-item">
                    Service
                  </a>
                  <a href="/" className="dropdown-item">
                    Donate
                  </a>
                  <a href="/directors" className="dropdown-item">
                    Directors
                  </a>
                  <a href="/facilitators" className="dropdown-item">
                    Facilitators
                  </a>
                  <a href="/" className="dropdown-item">
                    News feed
                  </a>
                </div>
              </div>
              <a href="contact.html" className="nav-item nav-link">
                Contact
              </a>
            </div>
            <div className="d-none d-lg-flex ms-2">
              <a
                className="btn btn-outline-primary py-2 px-3"
                href=""
                style={{ border: "1px solid #008000 ", color: "#fff" }}
              >
                Donate Now
                <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                  <i className="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
