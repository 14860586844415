import { Container, Typography } from "@mui/material";
import * as React from "react";
import { directors } from "../directors";
export default function Directors() {
  return (
    <div>
      <div
        class="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">
            Directors
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a class="text-white" href="#">
                  Pages
                </a>
              </li>
              <li
                class="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Directors
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container>
        <Typography variant="h3">Meet Our Visionary Directors</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "justify", lineHeight: 2 }}
        >
          Behind every successful endeavor is a team of dedicated leaders who
          steer the course and shape the future. At Livelihood And Environment
          Ghana, our vision is guided by a dynamic group of directors who
          possess unwavering commitment and a profound sense of purpose. Our
          directors bring a wealth of experience, expertise, and a shared
          passion for creating positive change in the world. They are the
          driving force behind our mission, providing strategic direction and
          unwavering support to our facilitators and the communities we serve.
          With diverse backgrounds in fields such as sustainable livelihood,
          human rights advocacy etc., our directors have a collective vision of
          a better, more equitable world. They are dedicated to realizing this
          vision, and their leadership inspires all of us to reach higher and
          achieve more. Explore the profiles of our visionary directors below to
          learn more about their inspiring journeys, the values that guide them,
          and the innovative approaches they employ to drive meaningful change.
          We are proud to have such distinguished leaders at the helm of
          Livelihood And Environment Ghana, steering us toward a brighter future
          for all.
        </Typography>
      </Container>
    </div>
  );
}
